import React, { FC, PropsWithChildren, ReactNode, useRef } from "react";

import {
  Text,
  AccordionItem as ChakraAccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

import { CSS_VARIABLE } from "@utils/constants";
import { isElementInViewport } from "@utils/utils";

type TAccordionItemProps = {
  title: ReactNode;
  inheritBgColor?: boolean;
  hideHeader?: boolean;
};

const AccordionItem: FC<PropsWithChildren<TAccordionItemProps>> = ({
  title,
  children,
  inheritBgColor,
  hideHeader,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inheritedBgColor = "var(--block-bg-color, var(--section-bg-color, white))";

  const scrollIntoView = () => {
    setTimeout(() => {
      const isOpen = buttonRef.current?.getAttribute("aria-expanded") === "true";

      if (isOpen && !isElementInViewport(containerRef.current)) {
        const style = window.getComputedStyle(document.body);
        const mainNavHeight = parseInt(style.getPropertyValue(CSS_VARIABLE.MAIN_NAV_HEIGHT), 10);
        const mobileNavHeight = parseInt(style.getPropertyValue(CSS_VARIABLE.MOBILE_NAV_HEIGHT), 10);
        const destination =
          (containerRef.current?.getBoundingClientRect().top || 0) +
          window.scrollY -
          (mobileNavHeight || mainNavHeight || 0);

        window.scrollTo({ top: destination, behavior: "smooth" });
      }
    }, 300);
  };

  return (
    <ChakraAccordionItem
      ref={containerRef}
      border="none"
      borderBottom="1px solid"
      borderColor="rgba(0, 0, 0, 0.16)"
      backgroundColor={inheritBgColor ? inheritedBgColor : "initial"}
    >
      <AccordionButton
        ref={buttonRef}
        fontSize="textSize.title"
        color="black"
        fontWeight="medium"
        borderBottom="none"
        py="4"
        px="0"
        _hover={{ background: inheritBgColor ? inheritedBgColor : "white" }}
        _expanded={{
          bg: inheritBgColor ? inheritedBgColor : "white",
          position: "sticky",
          top: hideHeader
            ? 0
            : `var(${CSS_VARIABLE.ACCORDION_CONTENT_OFFSET}, var(${CSS_VARIABLE.MOBILE_NAV_HEIGHT},
            var(${CSS_VARIABLE.MAIN_NAV_HEIGHT}, 80px)))`,
          zIndex: "1",
        }}
        onClick={scrollIntoView}
      >
        <Text flex="1" textAlign="left">
          {title}
        </Text>
        <AccordionIcon w="24px" h="24px" />
      </AccordionButton>
      <AccordionPanel
        px={0}
        pb={4}
        borderTop="1px solid"
        borderTopColor="rgba(0, 0, 0, 0.16)"
        sx={{ [CSS_VARIABLE.ACCORDION_CONTENT_OFFSET]: "0" }}
      >
        {children}
      </AccordionPanel>
    </ChakraAccordionItem>
  );
};

export default AccordionItem;
